import { Table, Button, Input, Alert, Modal, Typography, Row, Col, Icon, Tooltip, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { SessionsApi } from '../../api/sessionsApi';

const Sessions = () => {
    const [dataSource, setDataSource] = useState([]);
    const [fetchError, setFetchError] = useState('');

    const fetchSessions = async () => {
        console.log('fetchSessions: ');
        const res = await SessionsApi.getSessions()
        if (res.error) {
            return setFetchError(res.error)
        }
        setDataSource(res)
    };

    useEffect(() => {
        fetchSessions();
        const timeout = setInterval(() => fetchSessions(), 5000);
        return () => clearInterval(timeout)
    }, []);

    const columns = [
        {
            title: 'DEVICE ID',
            dataIndex: 'deviceId',
            key: 'deviceId',
            width: '20%',
        },
        {
            title: 'CONNECTED',
            dataIndex: 'connected',
            key: 'connected',
            width: '20%',
            render: (text, record) => record.connected && ('ONLINE')
        },
        {
            title: 'DEVICE IP',
            dataIndex: 'deviceIp',
            key: 'deviceIp',
            width: '20%',
        },
        {
            title: 'CLIENT IP',
            dataIndex: 'clientIp',
            key: 'clientIp',

            width: '20%',
        },
        {
            title: 'ACTION',
            key: 'actions',
            width: '10%',
            render: (text, record) => {
                return (
                    <span>
                        <Popconfirm title="Sure to disconnect?" onConfirm={() => {
                            SessionsApi.disconnectDevice(record.deviceId)
                            fetchSessions();
                            }
                        }
                            >
                            <Button type="danger">
                                Disconnect
                            </Button>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    return (
        <>
            {fetchError && <Alert message={fetchError} type="error" />}
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={'deviceId'}
                    columns={columns}
                />
        </>
    );
};

export default Sessions