import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../utils/Util';

export const PublicRoute = ({...props}) => {
    const [isLogin, setIsLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        isAuthenticated().then(res=> {
            setIsLogin(res)
            setIsLoading(false)
        })
    }, []);
    if (isLoading) return <div></div>
  return (
    isLogin
    ? (<Redirect to='/admin/dashboard' />)
    : (<Route {...props} />)
  )
}
