import { Button } from 'antd';
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom';
import { ADMIN_URL, LOGIN_URL } from '../../config/env';
import styles from './ChangePass.module.css';


export const ChangePass = () => {
    const history = useHistory();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const oldPasswordInputHandler = (e) => {
        setOldPassword(e.target.value)
    }
    const newPasswordInputHandler = (e) => {
        setNewPassword(e.target.value)
    }
    const confirmPasswordInputHandler = (e) => {
        setConfirmPassword(e.target.value)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        const user = {
            oldPassword,
            newPassword,
            confirmPassword,
        }
        console.log('user: ', user);

        if (!(oldPassword ||
            newPassword ||
            confirmPassword)) {
            return setErrorMessage('Fields must not be empty')
        }
        if (newPassword !== confirmPassword) {
            return setErrorMessage('Passwords are not the same')
        }
        fetch(ADMIN_URL + '/change', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            },
            body: JSON.stringify(user)
        }).then(async (res) => {
            console.log('res: ', res);
            const data = await res.json()
            if (res.status === 200) {
                sessionStorage.setItem("token", data.token);
                history.push('/admin/dashboard')

            } else {
                setErrorMessage(data.message)
            }

        });

        // 
    };

    return (
        <div className={styles.loginCard}>
            <h1>Change password</h1>
            <div className={styles.login_h2_flash}>

                <span className={styles.flash}>
                    {errorMessage}
                </span>
            </div>
            <form action={LOGIN_URL} method="post" onSubmit={submitHandler}>
                <div className={styles.form}>
                    <div className={`${styles.formElements}, ${styles.label}`}>
                        <label for="oldPassword">Old Password</label>
                    </div>
                    <div className={styles.formElements}>
                        <input onChange={oldPasswordInputHandler} value={oldPassword} type="password" name="oldPassword" placeholder="Old Password" required />
                    </div>
                    <div className={`${styles.formElements}, ${styles.label}`}>
                        <label for="newPassword">New password</label>
                    </div>
                    <div className={styles.formElements}>
                        <input onChange={newPasswordInputHandler} type="password" name="newPassword" value={newPassword} placeholder="New password" required />
                    </div>
                    <div className={`${styles.formElements}, ${styles.label}`}>
                        <label for="confirmPass">Confirm password</label>
                    </div>
                    <div className={styles.formElements}>
                        <input onChange={confirmPasswordInputHandler} type="password" name="confirmPassword" value={confirmPassword} placeholder="Confirm password" required />
                    </div>

                    <div className={styles.formElements}>
                        <input type="submit" className={styles.login} value="Change" />
                    </div>
                </div>
            </form>
        </div>
    )
}
