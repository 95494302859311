import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../utils/Util';

export const PrivateRoute = ({ children, ...rest }) => {

    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        isAuthenticated().then(res=> {
            setIsLogin(res)
            setIsLoading(false)
        })
    },[]);
      if (isLoading) return <div></div>
      if(rest.location.pathname === '/admin'){
        return <Redirect to='/admin/dashboard'/>;
      }
      
      if (isLogin) return <Route {...rest} render={() => children} />;
      return <Redirect to='/admin/login'/>;
    }
