import { COMMON_URL } from '../config/env';

export const LOGS_LIMIT = 100;

export const LogsApi = {
    async getLogs(params) {

        
        try {
            const res = await fetch(COMMON_URL + '/logs?' + new URLSearchParams({limit: LOGS_LIMIT, ...params}), {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                const tokens = await res.json();
                return { error: tokens.message || tokens.error || tokens.errors || res.statusText};
            }
            const config = await res.json();
            return config;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async getMSLogs(params) {

        
        try {
            const res = await fetch(COMMON_URL + '/ms-logs?' + new URLSearchParams({limit: LOGS_LIMIT, ...params}), {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                const tokens = await res.json();
                return { error: tokens.message || tokens.error || tokens.errors || res.statusText};
            }
            const config = await res.json();
            return config;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
};
