import React, { Fragment, useEffect, useState } from 'react'
import { LogsApi, LOGS_LIMIT } from '../../api/logsApi';
import { Alert, Card, Cascader, Icon, Input, Pagination, Table, Typography, Button, DatePicker } from 'antd';
import moment from 'moment';
import { Resizable } from 'react-resizable';
import './MSLogsPage.css';

const SEARCH_OPTIONS = [
    {
        value: false,
        label:'EQUALS'
    },
    {
        value: true,
        label:'CONTAINS'
    },  
]

const columnsDefault = [
    {
        title: 'ID'.toUpperCase(),
        dataIndex: 'id',
        key: 'id',
        width: 60,
        sorter: (a, b) => null
    },
    {
        title: 'Date'.toUpperCase(),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        render: (text, record) => {
            return moment(text, 'YYYY-MM-DDTHH-mm-ssZ').format(
                'DD-MM-YYYY HH-mm-ss'
            );
        },
        sorter: (a, b) => null
    },
    {
        title: 'Level'.toUpperCase(),
        dataIndex: 'level',
        key: 'level',
        width: 70,
        render: (text, record) => {
            return (
                <Typography.Text style={{ color: text === 'info' ? 'green' :  text === 'warn' ? 'orange' : 'red' }} >{text.toUpperCase()}</Typography.Text>
            )
        },
        sorter: (a, b) => null
    },
    {
        title: 'process id'.toUpperCase(),
        dataIndex: 'processId',
        key: 'processId',
        ellipsis: true,
        width: 70,
        sorter: (a, b) => null
    },
    {
        title: 'message'.toUpperCase(),
        dataIndex: 'message',
        key: 'message',
        width: 300,
        sorter: (a, b) => null
    },
    {
        title: 'stream Path'.toUpperCase(),
        dataIndex: 'streamPath',
        key: 'streamPath',
        width: 100,
        sorter: (a, b) => null
    },
    {
        title: 'stream Id'.toUpperCase(),
        dataIndex: 'streamId',
        key: 'streamId',
        width: 100,
        sorter: (a, b) => null
    },
    {
        title: 'client ip'.toUpperCase(),
        dataIndex: 'ip',
        key: 'ip',
        width: 100,
        sorter: (a, b) => null,
        
    },
    // {
    //     title: 'user Agent'.toUpperCase(),
    //     dataIndex: 'userAgent',
    //     key: 'userAgent',
    // },
]

const { RangePicker } = DatePicker;

const ResizableTitle = (props) => {
    const { onResize, onResizeStart, onResizeStop, width, ...restProps } = props;
    if (!width) {
      return <th {...restProps} />;
    }
    return (
      <Resizable
        width={width}
        height={0}
        handle={
          <div
            className="react-resizable-handle"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
          />
        }
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        onResize={onResize}
        draggableOpts={{
          enableUserSelectHack: false,
        }}
      >
        <th {...restProps} />
      </Resizable>
    );
};

const MSLogsPage = () => {
    const [fetchError, setFetchError] = useState('')
    const [logs, setLogs] = useState({})
    const [loading, setLoading] = useState(false)

    const [sortOptions, setSortOptions] = useState([])
    const [sortOrder, setSortOrder] = useState({ order: 'descend', columnKey: 'id' })

    const [dateOption, setDateOption] = useState({startDate: undefined, endDate: undefined})

    const [searchText, setSearchText] = useState('')
    const [searchType, setSearchType] = useState()
    const [searchLike, setSearchLike] = useState(true)

    const [enableSort, setEnableSort] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)

    const [columns, setColumns] = useState(columnsDefault)

    const getSearchParams = () => {
        const textLike = '%' + searchText + '%'
        const textEq   = searchText

        return {[searchType]: searchLike ? textLike : textEq}
    }
    const getDateParams = () => ({ 
        startDate: dateOption.startDate.format('YYYY-MM-DD HH:mm:ss'), 
        endDate:   dateOption.endDate.format('YYYY-MM-DD HH:mm:ss'), 
    })

    const fetchLogs = async (params) => {
        setLoading(true)
        const res = await LogsApi.getMSLogs(params)
        if (res.error) {
            setLoading(false)
            return setFetchError(res.error)
        }
        setLogs(res)
        setLoading(false)
    };
    useEffect(() => {
        fetchLogs()
        const sOptions = columnsDefault.reduce((accumulator, currentValue) => {
            if (currentValue.dataIndex === 'createdAt') return accumulator
            
            const optObj = {
                value: currentValue.dataIndex,
                label: currentValue.title,
            }
         
            return [...accumulator, optObj]
        }, []);
        setSortOptions(sOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchNextPageLogs = (page) => {
        setCurrentPage(page)
        const params = {
            offset: LOGS_LIMIT * (page - 1)
        }

        const { order, columnKey } = sortOrder
        if (!!order) Object.assign(params, {order: `${columnKey}:${order}`})
        if (!isDateDisabled) Object.assign(params, getDateParams())
        if (!isFindDisabled) Object.assign(params, getSearchParams())

        fetchLogs(params)
    }

    const onSearchTypeChange = (value) => {
        setSearchType(value)
    }

    const onSearchLikeChange = (value) => {
        setSearchLike(value[0])
    }

    const onSearchTextChange = (event) => {
        setSearchText(event.target.value)
    }

    const onSearchStart = () => {

        const params = getSearchParams()
        if (!isDateDisabled) Object.assign(params, getDateParams())

        fetchLogs(params)
    }

    const onSearchReset = () => {
        setSearchText('')
        setSearchLike(false)
        setSortOrder({ columnKey: undefined, order: undefined })
        setDateOption({ startDate: undefined, endDate: undefined})
        fetchLogs()
    }

    const isFindDisabled = !(searchText && searchType)
    const isDateDisabled = !(dateOption.startDate && dateOption.endDate)

    const handleSortChange = async (pagination, filters, sorter) => {

        if (!enableSort) return;

        setSortOrder({
            columnKey: sorter.columnKey,
            order: sorter.order
        })

        const params = !!sorter.order ? {order: `${sorter.columnKey}:${sorter.order}`} : {}

        if (!isFindDisabled) Object.assign(params, getSearchParams())
        if (!isDateDisabled) Object.assign(params, getDateParams())

        fetchLogs(params)
    }

    const onChangeDate = (value, dateString) => {
        setDateOption({ startDate: value[0], endDate: value[1] })
    }
      
    const onOkDate = (value) => {
        setDateOption({ startDate: value[0], endDate: value[1] })
        const params = { 
            startDate: value[0].format('YYYY-MM-DD HH:mm:ss'), 
            endDate:   value[1].format('YYYY-MM-DD HH:mm:ss'), 
        }
      
        if (!isFindDisabled) Object.assign(params, getSearchParams())

        fetchLogs(params)
    }


    const components = {
        header: {
          cell: ResizableTitle,
        },
    };

    const handleResize = (index) =>  
        (_, { size }) => {
            const newColumns = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width < columnsDefault[index].width ? columnsDefault[index].width : size.width,
            };
            setColumns(newColumns);
    };

    const resizableColumns = columns.map((col, index) => ({
        ...col,
        sortOrder: sortOrder.columnKey === col.key && sortOrder.order,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
          onResizeStart: () => setEnableSort(false),
          onResizeStop:  () => setTimeout(() => setEnableSort(true), 1)
        }),
    })); 

    return (
        <>
            {fetchError && <Alert message={fetchError} type="error" />}
            <Card 
                title={
                    <Fragment>
                        <Icon type="align-left" />
                        <span style={{ paddingLeft: "12px", fontSize: "16px" }}>Logs</span>
                    </Fragment>
                }
            >
                <Typography.Text
                    style={{ marginRight: 10 }}
                >
                    Find by:
                </Typography.Text>
                <Cascader 
                    options={sortOptions} 
                    onChange={onSearchTypeChange} 
                    placeholder="Please select" 
                    style={{ marginBottom: 20, marginRight: 6 }} 
                />
                 <Cascader 
                    options={SEARCH_OPTIONS} 
                    value={[searchLike]}
                    onChange={onSearchLikeChange} 
                    placeholder="Please select" 
                    style={{ marginBottom: 20, marginRight: 6 }} 
                />
                <Input
                    style={{
                        width: 300
                    }}
                    value={searchText}
                    onChange={onSearchTextChange}
                />
                <Button 
                    style={{ marginLeft: 16, marginRight: 16 }} 
                    type="primary" 
                    onClick={onSearchStart}
                    disabled={isFindDisabled}
                >
                    Find
                </Button>
                
                <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={['Start Time', 'End Time']}
                    onChange={onChangeDate}
                    onOk={onOkDate}
                    value={[dateOption.startDate, dateOption.endDate]}
                    disabledDate={(currentDate) => currentDate && currentDate.isAfter(moment())}
                />
                <Button 
                    style={ {marginLeft: 16 }} 
                    type="danger" 
                    onClick={onSearchReset}
                    disabled={isFindDisabled && isDateDisabled}
                >
                    Reset
                </Button>
                <Table
                    components={components}
                    style={{ marginBottom: 20 }}
                    pagination={false}
                    size='small'
                    dataSource={logs.logs}
                    loading={loading}
                    rowKey={'id'}
                    columns={resizableColumns}
                    bordered
                    onChange={handleSortChange}
                />
                <Pagination 
                    defaultPageSize={LOGS_LIMIT} 
                    total={logs.count} 
                    showTotal={total => `Total ${total} items`}
                    onChange={p => fetchNextPageLogs(p)}
                    current={currentPage}
                />
            </Card>
        </>
    )
}

export default MSLogsPage