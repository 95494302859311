import { COMMON_URL } from '../config/env';

export const SessionsApi = {
    async getSessions() {
        try {
            const res = await fetch(COMMON_URL + '/sessions', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            const sessions = await res.json();
            if (res.status !== 200) {
                const sessions = await res.json();
                return { error: sessions.error || sessions.errors || res.statusText};
            }
            console.log('sessions: ', sessions);
            return sessions;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async disconnectDevice(deviceId) {
        const body = {deviceId};
        try {
            const res = await fetch(COMMON_URL + '/sessions', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            const deleteStatus = await res.json();
            if (res.status !== 200) {
                const deleteStatus = await res.json();
                return { error: deleteStatus.error || deleteStatus.errors || res.statusText};
            }
            return deleteStatus;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
};
