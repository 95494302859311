import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route, Redirect, Switch } from "react-router-dom";
import { Divider, Icon, Layout, Menu } from 'antd';
import { createBrowserHistory } from 'history';
import Dashboard from "./containers/dashBoard/Dashboard";
import Settings from "./containers/settings/Settings";
import Streams from "./containers/streams/Streams";

import "./App.css"
import { Login } from './containers/login/Login';
import { PrivateRoute } from './routers/PrivateRoute';
import { LOGOUT_URL } from './config/env';
import { PublicRoute } from './routers/PublicRoute';
import Tokens from './containers/tokens/Tokens';
import {ChangePass} from './containers/changePass/ChangePass';
import LogsPage from './containers/logsPage/LogsPage';
import MSLogsPage from './containers/msLogsPage/MSLogsPage';
import Sessions from './containers/sessions/Sessions';


const { Header, Sider, Content, Footer } = Layout;

class App extends Component {
    

    fullTitle = "SitMediaServer";

    shortTitle = "SIT";

    state = {
        collapsed: false,
        title: this.fullTitle,
        pathname: "/",
        selectedKeys: window.location.pathname
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            title: this.state.collapsed ? this.fullTitle : this.shortTitle,
        });
    }
    logout = () => {
        fetch(LOGOUT_URL, {method: 'POST'})
        sessionStorage.clear()
    }

    UNSAVE_componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps, nextContext);
    }

    render() {
        return (
            <Router>
                <Switch>
                    <PublicRoute exact path="/admin/login" component={Login} />
                    <PrivateRoute path="/admin">
                        <Layout style={{ minHeight: "100vh" }}>
                            <Sider
                                width={256}
                                trigger={null}
                                collapsible
                                style={{backgroundColor: 'rgb(36,41,46)'}}
                                collapsed={this.state.collapsed}>
                                

                                <div className="logo"><h1>{this.state.title}</h1></div>

                                <Menu theme="dark" style={{backgroundColor: 'rgb(36,41,46)'}} mode="inline"
                                    defaultSelectedKeys={this.state.selectedKeys}
                                >
                                    <Menu.Item key="/admin/dashboard">
                                        <Link to="/admin/dashboard">
                                            <Icon type="dashboard" />
                                            <span>Dashboard</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/sessions">
                                        <Link to="/admin/sessions">
                                            <Icon type="api" />
                                            <span>Sessions</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/logs">
                                        <Link to="/admin/logs">
                                            <Icon type="align-left" />
                                            <Icon type="swap" />
                                            <span>Connection Logs</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/ms-logs">
                                        <Link to="/admin/ms-logs">
                                            <Icon type="align-left" />
                                            <Icon type="cluster" />
                                            <span>Media Server Logs</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/streams">
                                        <Link to="/admin/streams">
                                            <Icon type="video-camera" />
                                            <span>Streams</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/tokens">
                                        <Link to="/admin/tokens">
                                            <Icon type="solution" />
                                            <span>Tokens</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/settings">
                                        <Link to="/admin/settings">
                                            <Icon type="setting" />
                                            <span>Server Settings</span>
                                        </Link>
                                    </Menu.Item>
                                    <Divider type="horizontal" />
                                    <Menu.Item style={{ backgroundColor: 'rgb(48,54,61)' }} key="/admin/changePass">
                                        <Link to="/admin/changePass">
                                            <Icon type="key" />
                                            <span>Change password</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item style={{ backgroundColor: 'rgb(48,54,61)' }} key="/logout" onClick={this.logout}>
                                        <Link to="/admin/login">
                                            <Icon type="logout" />
                                            <span>logout</span>
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            </Sider>
                            <Layout>
                                <Header style={{ background: '#fff', padding: 0 }}>
                                    <Icon
                                        className="trigger"
                                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={this.toggle}
                                    />
                                </Header>
                                <Content style={{
                                    margin: '24px 16px', minHeight: 280,
                                }}>
                                    <Switch>
                                        <Route path="/admin/dashboard" component={Dashboard} />
                                        <Route path="/admin/streams" component={Streams} />
                                        <Route path="/admin/settings" component={Settings} />
                                        <Route path="/admin/tokens" component={Tokens} />
                                        <Route path="/admin/changePass" component={ChangePass} />
                                        <Route path="/admin/logs" component={LogsPage} />
                                        <Route path="/admin/ms-logs" component={MSLogsPage} />
                                        <Route path="/admin/sessions" component={Sessions} />
                                    </Switch>
                                </Content>
                                <Footer style={{ textAlign: 'center' }}>
                                    Stream-Server
                                </Footer>
                            </Layout>

                        </Layout>
                    </PrivateRoute>
                </Switch>

            </Router>
        );
    }

}

export default App;
