import { COMMON_URL } from '../config/env';

export const TokensApi = {
    async getTokens() {
        try {
            const res = await fetch(COMMON_URL + '/tokens', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });

            // if (!res.ok) {
            //     return {
            //         error: res.statusText
            //     }
            // }
            const tokens = await res.json();
            if (res.status !== 200) {
                const tokens = await res.json();
                return { error: tokens.error || tokens.errors || res.statusText};
            }
            return tokens;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async updateToken (token) {
        const updatedToken = {
            id: token.id,
            token: token.token,
            tokenGroup: token.tokenGroup,
        };
        try {
            const res = await fetch(COMMON_URL + '/tokens', {
                method: 'PUT',
                body: JSON.stringify(updatedToken),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                const resData = await res.json();
                return { error: resData.errors || resData.error || res.statusText}
            }
            return {}
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async createToken (token) {
        try {
            const res = await fetch(COMMON_URL + '/tokens', {
                method: 'POST',
                body: JSON.stringify(token),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                console.log('res: ', res);
                const resData = await res.json();
                return { error: resData.errors || resData.error || res.statusText}
            }
            return {}
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async deleteToken (token) {
        try {
            const res = await fetch(COMMON_URL + '/tokens', {
                method: 'DELETE',
                body: JSON.stringify(token),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                console.log('res: ', res);
                const resData = await res.json();
                return { error: resData.errors || resData.error || res.statusText}
            }
            return {}
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    }
};
