import { COMMON_URL } from '../config/env';

export const ConfigApi = {
    async getConfig() {
        try {
            const res = await fetch(COMMON_URL + '/config', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });

            // if (!res.ok) {
            //     return {
            //         error: res.statusText
            //     }
            // }
            const config = await res.json();
            if (res.status !== 200) {
                const tokens = await res.json();
                return { error: tokens.error || tokens.errors || res.statusText};
            }
            return config;
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
    async updateConfig (timeout) {
        try {
            const res = await fetch(COMMON_URL + '/config', {
                method: 'PUT',
                body: JSON.stringify({timeout}),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'), // for __DEV__
                },
            });
            if (res.status !== 200) {
                const resData = await res.json();
                console.log('resData: ', resData);
                return { error: resData.errors || resData.error || res.statusText}
            }
            return {}
        } catch (error) {
            console.log('error: ', error);
            return { error };
        }
    },
};
