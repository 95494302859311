import React, { Component, Fragment } from 'react';
import { Alert, Button, Card, Icon, Input, Popconfirm, Table, Typography } from "antd";
import { bytesToSize, secondsToDhms } from "../../utils/Util";
import { API_URL } from '../../config/env';
import { withRouter } from 'react-router-dom';
import { ConfigApi } from '../../api/configApi';



class Settings extends Component {

    state = {
        data: [],
        loading: false,
        timeout: null,
        editingKey: '',
        editingValue: '',
        errorMsg: ''
    };

    columns = [{
        dataIndex: 'name',
        key: 'name',
        width: 200
    }, {
        dataIndex: 'value',
        key: 'value',
    }];

    settingsColumns = [{
        dataIndex: 'name',
        key: 'name',
        width: 300
    }, {
        dataIndex: 'value',
        key: 'value',
        width: 100,
        render: (text, record) => {
            if (this.isEditing(record)) {
                return (
                    <Input
                        value={this.state.editingValue}
                        onChange={(e) => this.handleChange(e, 'tokenGroup')}
                    />
                );
            }
            return text;
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => {
            return (
                <span>
                    {this.isEditing(record) ? (
                        <span>
                            <Button style={{marginRight: 16}}  type="primary" onClick={this.handleSave}>
                                Save
                            </Button>
                            <Button onClick={() => this.setState({editingKey: ''})}>Cancel</Button>
                        </span>
                    ) : (
                        <>
                            <Button style={{ marginRight: 16 }} type="primary" onClick={() => this.handleEdit(record)} >
                                Edit
                            </Button>
                        </>
                    )}
                </span>
            );
        },
    },
    ];

    componentDidMount() {
        this.fetch();
    }

    fetch = () => {
        this.setState({ loading: true });
        const goBack = this.props.history.push
        fetch(API_URL + "/server", {
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('token'),
            },
        }).then(function (response) {
            if (response.status === 401) {
                goBack('/admin/login')
            }
            return response.json();
        }).then((data) => {
            // Read total count from server
            let osInfo = { key: 0, name: 'OS', value: data.os.arch + "_" + data.os.platform + "_" + data.os.release };
            let cpuInfo = { key: 1, name: "CPU", value: data.cpu.num + " x " + data.cpu.model };
            let memInfo = { key: 2, name: "Memory", value: bytesToSize(data.mem.totle) };
            let nodeInfo = { key: 3, name: "Node.js", value: data.nodejs.version };
            let uptimeInfo = { key: 4, name: "Uptime", value: secondsToDhms(data.nodejs.uptime) };
            let versionInfo = { key: 5, name: "Version", value: data.version };
            this.setState({
                data: [osInfo, cpuInfo, memInfo, nodeInfo, uptimeInfo, versionInfo],
            });

        }).catch(e => {
            this.setState({
                loading: false,
            });
        });
        ConfigApi.getConfig().then(res => {
            this.setState({
                loading: false,
                timeout: [{ key: 1, name: 'Disconnect Timeout Seconds', value: res.timeout / 1000 }],
            });
        })
    }

    isEditing = (record) => record.key === this.state.editingKey;

    handleEdit = (record) => {
        this.setState({
            editingKey: record.key,
            editingValue: record.value
        })

    }
    handleChange = (e) => {
        this.setState({
            editingValue: e.target.value
        })
    }
    updateTimeout = async () => {
        const res = await ConfigApi.updateConfig(this.state.editingValue * 1000)
        if (res.error) {
            const messages = res.error.length ? res.error.map(e => e.message || e.msg) : res.error.message || res.error
            return this.setState({
                errorMsg: messages
            })
        }
    }

    handleSave = () => {
        this.updateTimeout()
        this.setState({
            editingKey: ''
        })
        if (this.state.timeout[0].value !== this.state.editingValue) {

            this.setState({
                errorMsg: 'The server needs to be rebooted'.toUpperCase()
            })
        }
        ConfigApi.getConfig().then(res => {
            this.setState({
                loading: false,
                timeout: [{ key: 1, name: 'Disconnect Timeout Seconds', value: res.timeout / 1000 }],
            });
        })
    };

    render() {
        return (
            <>
                <Card title={<Fragment>
                    <Icon type="control" />
                    <span style={{ paddingLeft: "12px", fontSize: "16px" }}>Settings</span>
                </Fragment>}>
                    {this.state.errorMsg && <Alert message={this.state.errorMsg} type="error" />}

                    <Table
                        dataSource={this.state.timeout}
                        columns={this.settingsColumns}
                        loading={this.state.loading}
                        pagination={false}
                        showHeader={false} />
                </Card>
                <Card title={<Fragment>
                    <Icon type="hdd" />
                    <span style={{ paddingLeft: "12px", fontSize: "16px" }}>Server Info</span>
                </Fragment>}>

                    <Table
                        dataSource={this.state.data}
                        columns={this.columns}
                        loading={this.state.loading}
                        pagination={false}
                        showHeader={false} />
                </Card>
            </>

        );
    }
};

export default withRouter(Settings);