import { Table, Button, Input, Alert, Modal, Typography, Row, Col, Icon, Tooltip, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_URL, COMMON_URL, LOGS_URL } from '../../config/env';
import moment from 'moment';
import { TokensApi } from '../../api/tokensApi';

const Tokens = () => {
    const [dataSource, setDataSource] = useState([]);
    const [editingToken, setEditingToken] = useState({});
    const [editingKey, setEditingKey] = useState('');
    const [fetchError, setFetchError] = useState('');
    const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);

    const fetchTokens = async () => {
        const res = await TokensApi.getTokens()
        if (res.error) {
            return setFetchError(res.error)
        }
        setDataSource(res)
    };

    const updateToken = async () => {
        const res = await TokensApi.updateToken(editingToken)
        if (res.error) {
            const messages = res.error.length ? res.error.map(e => e.message || e.msg) : res.error.message || res.error
            return setFetchError(JSON.stringify(messages))
        }
        fetchTokens();
    }

    const deleteToken = async (token) => {
        const res = await TokensApi.deleteToken(token)
        if (res.error) {
            return setFetchError(res.error)
        }
        fetchTokens()
    }

    useEffect(() => {
        fetchTokens();
    }, []);

    const isEditing = (record) => record.id === editingKey;

    const handleEdit = (record) => {
        setEditingKey(record.id);
        setEditingToken(record);
    };

    const handleChange = (e, name) => {
        const updatedData = { ...editingToken };
        updatedData[name] = e.target.value;
        setEditingToken(updatedData);
    };

    const handleSave = () => {
        updateToken();
        setEditingKey('');
    };

    const onAddTokenModalClose = () => {
        setAddTokenModalVisible(false);
        fetchTokens()
    };

    

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
        },
        {
            title: 'Token Group',
            dataIndex: 'tokenGroup',
            key: 'tokenGroup',

            width: '15%',
            render: (text, record) => {
                if (isEditing(record)) {
                    return (
                        <Input
                            value={editingToken.tokenGroup}
                            onChange={(e) => handleChange(e, 'tokenGroup')}
                        />
                    );
                }
                return text;
            },
        },
        {
            title: 'Token',
            dataIndex: 'token',
            key: 'token',
            width: '35%',
            render: (text, record) => {
                if (isEditing(record)) {
                    return (
                        <Input
                            value={editingToken.token}
                            onChange={(e) => handleChange(e, 'token')}
                        />
                    );
                }
                return text;
            },
        },
        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (text, record) => {
                return moment(text, 'YYYY-MM-DDTHH-mm-ssZ').format(
                    'DD-MM-YYYY HH-mm-ss'
                );
            },
        },
        {
            title: 'updatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '15%',
            render: (text, record) => {
                return moment(text, 'YYYY-MM-DDTHH-mm-ssZ').format(
                    'DD-MM-YYYY HH-mm-ss'
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (text, record) => {
                const editable = isEditing(record);
                return (
                    <span>
                        {editable ? (
                            <span>
                                <Button style={{marginRight: 16}} type="primary" onClick={() => handleSave()}>
                                    Save
                                </Button>
                                <Button onClick={() => setEditingKey('')}>Cancel</Button>
                            </span>
                        ) : (
                            <>
                                <Button style={{marginRight: 16}} type="primary" onClick={() => handleEdit(record)}>
                                    Edit
                                </Button>
                                <Popconfirm title="Sure to delete?" onConfirm={() => deleteToken(record)}>
                                    <Button type="danger">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                    </span>
                );
            },
        },
    ];

    return (
        <>
            <Button type='primary' style={{ marginBottom: 20 }} onClick={() => setAddTokenModalVisible(true)}>
                Create TOKEN
            </Button>
            {fetchError && <Alert message={fetchError} type="error" />}
            {dataSource.length && (
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={'id'}
                    columns={columns}
                />
            )}
            <AddTokenModal
                isVisible={addTokenModalVisible}
                onClose={onAddTokenModalClose}
            />
        </>
    );
};

const AddTokenModal = (props) => {


    const [newToken, setNewToken] = useState({ token: '', tokenGroup: '' });
    const [fetchError, setFetchError] = useState('');


    const _onClose = () => {
        props.onClose()
        setNewToken({ token: '', tokenGroup: '' })
    }

    const onTokenChange = (e) => {
        setNewToken({ ...newToken, [e.target.name]: e.target.value})
    }

    const validateToken = (token) => {
        if (!token.token.trim() || !token.tokenGroup.trim()) {
            setFetchError('Fields must be not Empty')
            return false
        }
        
        return {token: token.token.trim(), tokenGroup: token.tokenGroup.trim()}
    } 
    const createToken = async () => {
        const validToken = validateToken(newToken)
        if (!validToken) {
            return
        }
        const res = await TokensApi.createToken(newToken)
        if (res.error) {
            const messages = res.error.length ? res.error.map(e => e.message || e.msg) : res.error
            return setFetchError(JSON.stringify(messages))
        }
        _onClose();
    }

    const generateRandomToken = () => {
        setNewToken({...newToken, token: crypto.randomUUID().replaceAll('-', '')})
    }

    return (
        <Modal
            title="Add New Token"
            visible={props.isVisible}
            onOk={createToken}
            onCancel={_onClose}
        >
            <Typography.Text>Token Group</Typography.Text>
            <Input placeholder='Token Group' name='tokenGroup' style={{ marginBottom: 20 }} value={newToken.tokenGroup} onChange={onTokenChange} />
            <Typography.Text>Token</Typography.Text>
            <Row gutter={16} type="flex" justify="space-between">
                <Col span={20} ><Input placeholder='Token' name='token' style={{ marginBottom: 20 }} value={newToken.token} onChange={onTokenChange} /></Col>
                <Col span={4} >
                    <Tooltip placement="top" title="Generate random">
                        <Button type='primary' style={{ width: '100%' }} onClick={generateRandomToken} >
                            <Icon type='sync' />
                        </Button>
                    </Tooltip>
                </Col>
            </Row>


            <Typography.Paragraph style={{ color: 'red' }}>{fetchError}</Typography.Paragraph>
        </Modal>
    )

}

export default Tokens;
